<template>
  <div class="qr-page-wrapper">
    <VueText sizeLevel="12" class="info" color="white-100" weightLevel="6">
      Raf üzerinde bulunan QR kodu okutunuz
    </VueText>
    <div class="qr-box">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <div class="dce-video-container"></div>
      <div class="dce-scanarea">
        <div class="dce-scanlight"></div>
      </div>
    </div>
    <div class="progress-wrapper">
      <VueText sizeLevel="9" class="progress-title" color="white-100">
        Tarama süreci
      </VueText>
      <div class="progress">
        <div
          class="progress-bar"
          :style="{
            width: percent,
          }"
        ></div>
      </div>
    </div>
    <input
      type="file"
      name="image"
      @change="getPhoto"
      @click="closeModal"
      ref="fileInput"
      hidden="true"
      capture="environment"
    />
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import RoutesImageRecognition from '@/router/routes/secure/RoutesImageRecognition';
import '../../../../../src/dbr';
import { mapGetters } from 'vuex';
import ImageRecognition from '@/services/Api/imageRecognition.js';
import { v4 as uuidv4 } from 'uuid';
import loadImage from 'blueimp-load-image';
import { BarcodeScanner } from 'dynamsoft-javascript-barcode';

export default {
  name: 'QRReader',
  components: { VueText },
  data: function() {
    return {
      isContextDestroyed: false,
      scanner: null,
      rack: {},
      uploadData: [],
      isSecondUrl: false,
      currentRackNumber: 0,
      isUpdate: false,
      percentCompleted: 0,
    };
  },
  computed: {
    ...mapGetters('app', ['getRacks', 'getQrResponse', 'getModalConfig', 'getCloseClick']),
    racks() {
      return this.getRacks || [];
    },
    percent() {
      return `${this.percentCompleted}%`;
    },
    isCloseClick() {
      return this.getCloseClick;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  mounted() {
    this.readQr();
    this.$route.params.id ? (this.isUpdate = true) : (this.isUpdate = false);
  },
  beforeDestroy() {
    this.isContextDestroyed = true;
    if (this.scanner) {
      this.scanner.destroyContext();
    }
  },
  watch: {
    percentCompleted: {
      handler: function() {},
      deep: true,
      immediate: true,
    },
    isCloseClick: {
      handler: function(isTrue) {
        isTrue && this.readQr();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async readQr() {
      try {
        this.percentCompleted = 0;
        this.scanner = await BarcodeScanner.createInstance();
        if (this.isContextDestroyed) {
          this.scanner.destroyContext();
          return;
        }
        await this.scanner.setUIElement(this.$el);
        this.scanner.onFrameRead = results => {
          if (results.length) {
            let data = {
              qrData: results[0].barcodeText,
            };
            this.getQrData(data);
            this.scanner.destroyContext();
          }
        };
        await this.scanner.open();
      } catch (ex) {
        console.error(ex);
      }
    },

    getQrData(data) {
      let config = {
        onUploadProgress: progressEvent => {
          this.percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        },
      };
      ImageRecognition.qrRead(data, config)
        .then(res => {
          if (res.status === 200 && res.data.Data && res.data.Data.images) {
            this.uploadData = res.data.Data.images;
            this.currentRackNumber = res.data.Data.images[0].rackNumber;
            if (this.racks.length === 0) {
              this.$store.dispatch('app/setQrResponse', {
                rackCount: res.data.Data.rackCount,
                customerName: res.data.Data.customerName,
                customerCode: res.data.Data.customerCode,
              });
              const modalConfig = {
                isModalOpened: true,
                modalText: `<div style="font-size: 16px; color: #79838e; margin-top: 20px">Mağaza Adı</div>
                        <div style="font-size: 20px; margin-top: 12px; margin-bottom:58px;">${res.data.Data.customerName}</div>`,
                isDoubleButton: true,
                firstButtonText: 'TAMAM',
                secondButtonText: 'İPTAL',
                modalConfirmationText: '',
                alertType: 'error',
                onCloseOpenQr: true,
                firstButtonFn: () => {
                  this.$refs.fileInput.click();
                },
                secondaryButtonFn: () => {
                  this.readQr();
                },
              };
              this.scanner.destroyContext();
              this.$store.dispatch('app/setModalConfig', modalConfig);
            } else {
              if (res.data.Data.customerCode === this.getQrResponse.customerCode) {
                if (this.racks.find(i => i.rackNumber == res.data.Data.images[0].rackNumber)) {
                  if (this.$route.params.id) {
                    const r = this.racks.find(i => i.id === this.$route.params.id);
                    if (r && r.rackNumber !== res.data.Data.images[0].rackNumber) {
                      const modalConfig = {
                        isModalOpened: true,
                        modalText: `
                        <div style="font-size: 20px; margin-top: 30px; margin-bottom:40px;">Değiştirmek istediğiniz raflar uyuşmuyor.</div>`,
                        firstButtonText: 'TAMAM',
                        modalConfirmationText: '',
                        alertType: 'error',
                        onCloseOpenQr: true,
                        firstButtonFn: () => {
                          this.readQr();
                        },
                      };
                      this.scanner.destroyContext();
                      this.$store.dispatch('app/setModalConfig', modalConfig);
                    } else {
                      this.$route.params.isSecondUrl
                        ? (this.isSecondUrl = true)
                        : (this.isSecondUrl = false);
                      this.isUpdate = true;
                      const modalConfig = {
                        isModalOpened: true,
                        modalText: `<div style="font-size: 16px; color: #79838e; margin-top: 20px">Mağaza Adı</div>
                        <div style="font-size: 20px; margin-top: 12px; margin-bottom:58px;">${res.data.Data.customerName}</div>`,
                        isDoubleButton: true,
                        firstButtonText: 'TAMAM',
                        secondButtonText: 'İPTAL',
                        modalConfirmationText: '',
                        alertType: 'error',
                        onCloseOpenQr: true,
                        firstButtonFn: () => {
                          this.$refs.fileInput.click();
                        },
                        secondaryButtonFn: () => {
                          this.readQr();
                        },
                      };
                      this.scanner.destroyContext();
                      this.$store.dispatch('app/setModalConfig', modalConfig);
                    }
                  } else if (
                    this.racks.filter(r => r.rackNumber === res.data.Data.images[0].rackNumber)
                      .length === 2
                  ) {
                    const modalConfig = {
                      isModalOpened: true,
                      modalText: `
                        <div style="font-size: 20px; margin-top: 30px; margin-bottom:40px;">1 raf için en fazla 2 fotoğraf yüklenebilir.</div>`,
                      firstButtonText: 'TAMAM',
                      modalConfirmationText: '',
                      alertType: 'error',
                      onCloseOpenQr: true,
                      firstButtonFn: () => {
                        this.readQr();
                      },
                    };
                    this.scanner.destroyContext();
                    this.$store.dispatch('app/setModalConfig', modalConfig);
                  } else {
                    const modalConfig = {
                      isModalOpened: true,
                      modalText:
                        'Okunan QR kodu için daha önce bir fotoğraf yüklediniz, bu QR kodu için yapmak istediğiniz işlem nedir?',
                      isDoubleButton: true,
                      firstButtonText: 'FOTOĞRAF EKLE',
                      secondButtonText: 'DEĞİŞTİR',
                      isBtnReverse: true,
                      modalConfirmationText: '',
                      onCloseOpenQr: true,
                      firstButtonFn: () => {
                        this.racks.find(i => i.isSecondUrl !== true)
                          ? (this.isSecondUrl = true)
                          : (this.isSecondUrl = false);
                        this.$refs.fileInput.click();
                      },
                      secondaryButtonFn: () => {
                        this.isUpdate = true;
                        this.$refs.fileInput.click();
                      },
                    };
                    this.scanner.destroyContext();
                    this.$store.dispatch('app/setModalConfig', modalConfig);
                  }
                } else {
                  if (
                    this.racks.find(i => i.rackNumber !== res.data.Data.images[0].rackNumber) &&
                    this.$route.params.id
                  ) {
                    const modalConfig = {
                      isModalOpened: true,
                      modalText: `
                        <div style="font-size: 20px; margin-top: 30px; margin-bottom:40px;">Değiştirmek istediğiniz raflar uyuşmuyor.</div>`,
                      firstButtonText: 'TAMAM',
                      modalConfirmationText: '',
                      alertType: 'error',
                      onCloseOpenQr: true,
                      firstButtonFn: () => {
                        this.readQr();
                      },
                    };
                    this.scanner.destroyContext();
                    this.$store.dispatch('app/setModalConfig', modalConfig);
                  } else {
                    const modalConfig = {
                      isModalOpened: true,
                      modalText: `<div style="font-size: 16px; color: #79838e; margin-top: 20px">Mağaza Adı</div>
                        <div style="font-size: 20px; margin-top: 12px; margin-bottom:58px;">${res.data.Data.customerName}</div>`,
                      isDoubleButton: true,
                      firstButtonText: 'TAMAM',
                      secondButtonText: 'İPTAL',
                      modalConfirmationText: '',
                      alertType: 'error',
                      onCloseOpenQr: true,
                      firstButtonFn: () => {
                        this.$refs.fileInput.click();
                      },
                      secondaryButtonFn: () => {
                        this.readQr();
                      },
                    };
                    this.scanner.destroyContext();
                    this.$store.dispatch('app/setModalConfig', modalConfig);
                  }
                }
              } else {
                const modalConfig = {
                  isModalOpened: true,
                  modalText: `<div style="margin-bottom: 58px;"><b>Üzgünüz</b>, QR kod bilgileri ile fotoğraf mağaza bilgileri uyuşmamaktadır.</div>`,
                  isDoubleButton: true,
                  alertType: 'warn',
                  firstButtonText: 'YENİDEN DENE',
                  secondButtonText: 'İPTAL',
                  modalType: 'alert',
                  onCloseOpenQr: true,
                  firstButtonFn: () => {
                    this.readQr();
                    this.$store.dispatch('app/setModalConfig', {});
                  },
                  secondaryButtonFn: () => {
                    this.$router.push({ name: 'Racks' });
                  },
                };
                this.$store.dispatch('app/setModalConfig', modalConfig);
                this.scanner.destroyContext();
              }
            }
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.Error &&
            err.response.data.Error.Message
          ) {
            const modalConfig = {
              isModalOpened: true,
              modalText: `<div style="margin-bottom: 58px;">${err.response.data.Error.Message}</div>`,
              alertType: 'warn',
              firstButtonText: 'Tamam',
              modalType: 'alert',
              onCloseOpenQr: true,
              firstButtonFn: () => {
                this.readQr();
              },
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
          } else {
            const modalConfig = {
              isModalOpened: true,
              modalText: `<div style="margin-bottom: 58px;">Hata Oluştu!</div>`,
              alertType: 'warn',
              firstButtonText: 'Tamam',
              modalType: 'alert',
              onCloseOpenQr: true,
              firstButtonFn: () => {
                this.readQr();
              },
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
            ImageRecognition.errorLog({
              postCode: this.getQrResponse.customerCode,
              detail: err.message,
            });
          }
        });
    },
    closeModal() {
      this.$store.dispatch('app/setModalConfig', {});
      document.body.onfocus = this.openQrReader;
    },
    openQrReader() {
      !this.$refs.fileInput.value && this.readQr();
    },
    getPhoto({ target }) {
      this.readFile(target.files)
        .then(data => {
          target.value = '';
          // set state
          this.rack.img = data;
          let date = new Date();
          let now = `${date.getFullYear()}/${date.getMonth() +
            1}/${date.getDate()} ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') +
            date.getMinutes()}`;
          this.rack.date = now;
          this.rack.rackNumber = this.currentRackNumber;
          if (this.isUpdate) {
            let index;
            if (this.$route.params.id) {
              index = this.racks.findIndex(i => i.id === this.$route.params.id);
            } else {
              index = this.racks.findIndex(i => i.rackNumber === this.rack.rackNumber);
            }
            this.rack.id = uuidv4();
            this.racks[index] = this.rack;
            this.$store.dispatch('app/setRacks', this.racks);
          } else {
            this.rack.id = uuidv4();
            this.$store.dispatch('app/setRacks', [...this.racks, this.rack]);
          }
        })
        .catch(err => {
          target.value = '';
          ImageRecognition.errorLog({
            postCode: this.getQrResponse.customerCode,
            detail: err.message,
          });
        });
      // upload file
      if (this.uploadData.length) {
        let uploadUrl = this.isSecondUrl
          ? this.uploadData[1].uploadUrl
          : this.uploadData[0].uploadUrl;
        this.rack.isSecondUrl = this.isSecondUrl;
        this.rack.images = this.uploadData;
        loadImage(target.files[0], { canvas: false, meta: true })
          .then(data => {
            var newCanvas = document.createElement('canvas');
            newCanvas.height = data.image.height;
            newCanvas.width = data.image.width;
            var ctx = newCanvas.getContext('2d');
            ctx.drawImage(data.image, 0, 0);
            newCanvas.toBlob(
              blob => {
                var img = new File([blob], 'test.jpg', { type: 'image/jpeg' });
                ImageRecognition.uploadImage(uploadUrl, img, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'No Auth',
                  },
                })
                  .then(res => {
                    if (res.status === 200) {
                      this.rack.isSuccess = true;
                      const idx = this.racks.findIndex(x => x.id === this.rack.id);
                      this.racks[idx].isSuccess = true;
                      this.$store.dispatch('app/setRacks', this.racks);
                      this.$store.dispatch(
                        'app/setQrResponse',
                        Object.assign({}, this.getQrResponse, {
                          rackCount: this.getQrResponse.rackCount,
                        }),
                      );
                    }
                  })
                  .catch(err => {
                    ImageRecognition.errorLog({
                      postCode: this.getQrResponse.customerCode,
                      detail: err.message,
                    });
                  });
              },
              'image/jpeg',
              0.95,
            );
          })
          .catch(err => {
            ImageRecognition.errorLog({
              postCode: this.getQrResponse.customerCode,
              detail: err.message,
            });
          });

        this.$router.push({
          name: `${RoutesImageRecognition.Racks.name}`,
        });
      }
    },

    readFile(files) {
      return new Promise((resolve, reject) => {
        if (!files || files.length === 0) {
          resolve();
          return;
        }
        const file = files[0];
        if (/^image\/\w+$/.test(file.type)) {
          if (URL) {
            resolve({
              loaded: true,
              name: file.name,
              type: file.type,
              url: URL.createObjectURL(file),
            });
          } else {
            reject(new Error('Browser desteklenmiyor.'));
            ImageRecognition.errorLog({
              postCode: this.getQrResponse.customerCode,
              detail: 'Browser desteklenmiyor.',
            });
          }
        } else {
          reject(new Error('Lütfen image tipinde bir görsel seçiniz.'));
          ImageRecognition.errorLog({
            postCode: this.getQrResponse.customerCode,
            detail: 'Lütfen image tipinde bir görsel seçiniz.',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.qr-page-wrapper {
  height: 100%;
  background-color: #959fae;
}
.info {
  max-width: 315px;
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
  margin-top: 60px;
}
.qr-box {
  width: 354px;
  height: 354px;
  background-color: transparent;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  border: 5px solid #c1cbdb;
  box-shadow: -11px 6px 30px 0px rgba(50, 50, 50, 0.57);

  span {
    background-color: transparent;
    width: 65px;
    height: 65px;
    position: absolute;
    &:nth-child(1) {
      top: -5px;
      left: -5px;
      border-top: 3px solid #fff;
      border-left: 3px solid #fff;
    }
    &:nth-child(2) {
      top: -5px;
      right: -5px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
    }
    &:nth-child(3) {
      bottom: -5px;
      right: -5px;
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
    }
    &:nth-child(4) {
      bottom: -5px;
      left: -5px;
      border-bottom: 3px solid #fff;
      border-left: 3px solid #fff;
    }
  }
}
.dce-video-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.dce-scanarea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dce-scanlight {
  display: none;
  width: 100%;
  height: 3%;
  position: absolute;
  animation: 3s infinite dce-scanlight;
  border-radius: 50%;
  box-shadow: 0px 0px 2vw 1px #00e5ff;
  background: #fff;
}

@keyframes scanner-scanlight {
  from {
    top: 0;
  }
  to {
    top: 50%;
  }
}
.progress-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 280px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;
}
.progress-title {
  margin-bottom: 20px;
}
.progress {
  display: flex;
  height: 9px;
  overflow: hidden;
  background-color: #e9ecef;
  border-radius: 4.5px;
  &-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-image: linear-gradient(315deg, #d20051, #ffa300);
    transition: width 0.8s ease;
    border-radius: 4.5px;
  }
}
</style>
